import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceTileComponent } from '../service-tiles/service-tile/service-tile.component';
import { ChipComponent } from '../chip/chip.component';
import { TranslationPipe } from '@mkk/translate';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { Categories, Category, Service, Services } from '../types';
import { ServiceTilesComponent } from '../service-tiles/service-tiles.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

export const filterServices = (services: Services, category: Category) => {
  return Object.values(services).filter(({ categoryTags }) => categoryTags.includes(category.tag));
};

@Component({
  selector: 'mkk-tiles-with-filter',
  standalone: true,
  imports: [
    CommonModule,
    ChipComponent,
    ServiceTileComponent,
    TranslationPipe,
    DropdownComponent,
    IconButtonComponent,
    ServiceTilesComponent,
  ],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [style({ transform: 'translateY(100%)' }), animate(150)]),
      transition('* => void', [animate(150, style({ transform: 'translateY(100%)' }))]),
    ]),
  ],
  templateUrl: './tiles-with-filter.component.html',
  styleUrl: './tiles-with-filter.component.scss',
})
export class TilesWithFilterComponent implements OnInit {
  @Input()
  services!: Services;

  @Input()
  categories!: Categories;

  @Input({ required: false })
  isLoggedIn = false;

  /*
   * Callback Parameter: href fom tile
   * */
  @Output() tileClick = new EventEmitter<Service>();

  @Output() filterClick = new EventEmitter<void>();

  ngOnInit(): void {
    this.activeCategory = this.categories.topService;
    this.categoryValues = Object.values(this.categories);
    setTimeout(() => {
      this.filteredServices = filterServices(this.services, this.activeCategory);
    }); // Trigger pageload animations
    this.initCategory();
  }

  filteredServices: Service[] = [];
  filteredCategories: Category[] = [];

  serviceValues!: Service[];
  categoryValues!: Category[];
  activeCategory!: Category;

  showMobileServiceFilter = false;

  initCategory() {
    this.filteredCategories = this.categoryValues.filter(({ tag }) =>
      this.serviceValues
        ?.map(({ categoryTags }) => categoryTags)
        .flat(1)
        .includes(tag),
    );
  }

  setActiveCategory(category: Category) {
    this.filterClick.emit();
    this.activeCategory = category;
    this.showMobileServiceFilter = false;
    this.filteredServices = [];
    setTimeout(() => {
      this.filteredServices = filterServices(this.services, this.activeCategory);
    }); // Trigger pageload animations
  }

  onTileClick(service: Service) {
    this.showMobileServiceFilter = false;
    this.tileClick.emit(service);
  }
}
