<div class="mkk-tiles-with-filter">
  <div class="mkk-tiles-with-filter__chips">
    <div class="desktop">
      @for (category of categoryValues; track category) {
        <mkk-chip
          [isActive]="category.tag === activeCategory.tag"
          [attr.aria-label]="category.ariaLabel"
          [label]="category.label"
          (click)="setActiveCategory(category)"
        />
      }
    </div>
    <div class="mobile">
      <mkk-chip
        svgIconName="mkk-filter"
        label="services.filter"
        (click)="showMobileServiceFilter = true"
      />
      <mkk-chip
        [isActive]="true"
        [label]="activeCategory.label"
      />
      @if (showMobileServiceFilter) {
        <div
          class="mobile-service-filter"
          [@flyInOut]
        >
          <div class="title">{{ 'services.mobileFilterTitle' | translate }}</div>
          <mkk-icon-button
            class="close-icon"
            variant="not-outlined"
            iconName="mkk-cross"
            (click)="showMobileServiceFilter = false"
          />
          <div class="items">
            @for (category of categoryValues; track category) {
              <div
                class="item"
                (click)="setActiveCategory(category)"
              >
                {{ category.label | translate }}
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
  <mkk-service-tiles
    class="mkk-tiles-with-filter__service-tiles"
    [services]="filteredServices"
    (tileClick)="onTileClick($event)"
    [isLoggedIn]="isLoggedIn"
  />
</div>
